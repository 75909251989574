.fispan {
    display: none;
}

input:invalid[focused="true"] {
    border: 1px solid red;
}

input:invalid[focused="true"]~.fispan {
    display: block;
}